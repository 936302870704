import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  links = [
    {url: '/account/settings', name: 'Аккаунт'},
    {url: '/account/integrations', name: 'Интеграции'},
    {url: '/account/statuses', name: 'Статусы'},
    {url: '/account/workers', name: 'Сотрудники'},
    {url: '/account/billing', name: 'Тарифы и оплата'},
  ]
  dist_uri:string = ""

  constructor(private router: Router) { }

  ngOnInit() {
    if(this.router.url === '/account' || this.router.url === '/account/settings'){
      this.router.navigate(['/account/settings'])
    }
  }

}
