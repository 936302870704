import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-funnel',
  templateUrl: './funnel.component.html',
  styleUrls: ['./funnel.component.css']
})
export class FunnelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
