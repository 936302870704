import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppComponent} from './app.component';
import {LoginPageComponent} from './components/login-page/login-page.component';
import {AppRoutingModule} from './app-routing.module';
import {AuthLayoutComponent} from './shared/layouts/auth-layout/auth-layout.component';
import {SiteLayoutComponent} from './shared/layouts/site-layout/site-layout.component';
import {RegisterPageComponent} from './components/register-page/register-page.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenInterceptor} from './shared/classes/token.interceptor';
import {OverviewPageComponent} from './components/overview-page/overview-page.component';
import {AnalyticsPageComponent} from './components/analytics-page/analytics-page.component';
import {HistoryPageComponent} from './components/history-page/history-page.component';
import {OrderPageComponent} from './components/order-page/order-page.component';
import {CategoriesPageComponent} from './components/categories-page/categories-page.component';
import { AccountComponent } from './components/account/account.component';
import { RequestsComponent } from './components/requests/requests.component';
import { FaqComponent } from './components/faq/faq.component';
import { BusinessCardComponent } from './components/business-card/business-card.component';
import { FunnelComponent } from './components/funnel/funnel.component';
import { AccountSettingsComponent } from './components/account/components/account-settings/account-settings.component';
import { AccountIntegrationsComponent } from './components/account/components/account-integrations/account-integrations.component';
import { AccountStatusesComponent } from './components/account/components/account-statuses/account-statuses.component';
import { AccountWorkersComponent } from './components/account/components/account-workers/account-workers.component';
import { AccountBillingComponent } from './components/account/components/account-billing/account-billing.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    AuthLayoutComponent,
    SiteLayoutComponent,
    RegisterPageComponent,
    OverviewPageComponent,
    AnalyticsPageComponent,
    HistoryPageComponent,
    OrderPageComponent,
    CategoriesPageComponent,
    AccountComponent,
    RequestsComponent,
    FaqComponent,
    BusinessCardComponent,
    FunnelComponent,
    AccountSettingsComponent,
    AccountIntegrationsComponent,
    AccountStatusesComponent,
    AccountWorkersComponent,
    AccountBillingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: TokenInterceptor
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
