import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {MaterialService} from '../../classes/material.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SiteLayoutComponent implements AfterViewInit {

  @ViewChild('floating') floatingRef: ElementRef

  links = [
    {url: '/requests', name: 'Заявки', icon:'zayavki'},
    {url: '/funnel', name: 'Воронка', icon:'voronka'},
    {url: '/analytics', name: 'Аналитика', icon:'analitika'},
    {url: '/business-card', name: 'Визитка', icon:'visitka'},
    {url: '/account', name: 'Аккаунт', icon:'account'},
    {url: '/faq', name: 'Помощь', icon:'help'}
  ]
  dist_uri:string = ""

  constructor(private auth:AuthService,
              private router: Router) {

    if ( environment.production ){
      this.dist_uri = "dist/"
    }
  }



  ngAfterViewInit() {
    // MaterialService.initializeFloatingButton(this.floatingRef)

  }

  logout(event: Event){
    event.preventDefault()
    this.auth.logout()
    this.router.navigate(['/login'])
  }


}
