import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthLayoutComponent} from './shared/layouts/auth-layout/auth-layout.component';
import {SiteLayoutComponent} from './shared/layouts/site-layout/site-layout.component';
import {LoginPageComponent} from './components/login-page/login-page.component';
import {RegisterPageComponent} from './components/register-page/register-page.component';
import {AuthGuard} from './shared/classes/auth.guard';
import {OverviewPageComponent} from './components/overview-page/overview-page.component';
import {AnalyticsPageComponent} from './components/analytics-page/analytics-page.component';
import {HistoryPageComponent} from './components/history-page/history-page.component';
import {OrderPageComponent} from './components/order-page/order-page.component';
import {CategoriesPageComponent} from './components/categories-page/categories-page.component';
import {AccountComponent} from './components/account/account.component';
import {RequestsComponent} from './components/requests/requests.component';
import {FunnelComponent} from './components/funnel/funnel.component';
import {BusinessCardComponent} from './components/business-card/business-card.component';
import {FaqComponent} from './components/faq/faq.component';
import {AccountSettingsComponent} from './components/account/components/account-settings/account-settings.component';
import {AccountIntegrationsComponent} from './components/account/components/account-integrations/account-integrations.component';
import {AccountStatusesComponent} from './components/account/components/account-statuses/account-statuses.component';
import {AccountWorkersComponent} from './components/account/components/account-workers/account-workers.component';
import {AccountBillingComponent} from './components/account/components/account-billing/account-billing.component';

const routes: Routes = [
  {
    path: '', component: AuthLayoutComponent, children: [
      {path: '', redirectTo: '/login', pathMatch: 'full'},
      {path: 'login', component: LoginPageComponent},
      {path: 'register', component: RegisterPageComponent}
    ]
  },
  {
    path: '', component: SiteLayoutComponent, canActivate: [AuthGuard], children: [
      {path:'overview', component: OverviewPageComponent},
      {path:'history', component: HistoryPageComponent},
      {path:'order', component: OrderPageComponent},
      {path:'categories', component: CategoriesPageComponent},

      {path:'account', component: AccountComponent, children:[
          {path:'settings', component: AccountSettingsComponent},
          {path:'integrations', component: AccountIntegrationsComponent},
          {path:'statuses', component: AccountStatusesComponent},
          {path:'workers', component: AccountWorkersComponent},
          {path:'billing', component: AccountBillingComponent},
        ]},
      {path:'requests', component: RequestsComponent},
      {path:'analytics', component: AnalyticsPageComponent},
      {path:'funnel', component: FunnelComponent},
      {path:'business-card', component: BusinessCardComponent},
      {path:'faq', component: FaqComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
