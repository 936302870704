import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthService} from '../../shared/services/auth.service';
import {MaterialService} from '../../shared/classes/material.service';

@Component({
  selector: 'app-login-page.container',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit, OnDestroy {

  form: FormGroup;
  aSub: Subscription

  constructor(private auth:AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.minLength(5)])
    })

    this.route.queryParams.subscribe((params: Params)=>{
      if(params['registered']){
        MaterialService.toast('Теперь вы можете зайти')
      } else if(params['accessDenied']){
        MaterialService.toast('Для начала авторизуйтесь в системе')
      } else if (params['sessionFailed']){
        MaterialService.toast('Войдите в систему заново')
      }
    })
  }
  ngOnDestroy(){
    if(this.aSub){
      this.aSub.unsubscribe()
    }
  }

  onSubmit(){
    this.form.disable()

    this.aSub = this.auth.login(this.form.value).subscribe(
      () => this.router.navigate(['/account/settings']),
      error => {
        MaterialService.toast(error.error.error)
        this.form.enable()
      }
    )
  }

}
